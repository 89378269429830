$breakpoint-xs: 600px;
$breakpoint-sm: 600px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

@media only screen and (max-width: $breakpoint-xs) {
  .dis-ab-l {
    order: 2;
  }

  .dis-ab-r {
    order: 1;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: $breakpoint-sm) {
  .dis-ab-l {
    order: 2;
  }

  .dis-ab-r {
    order: 1;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: $breakpoint-md) {
  .dis-ab-l {
    order: 2;
  }

  .dis-ab-r {
    order: 1;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: $breakpoint-lg) {
  .dis-ab-l {
    order: 1;
  }

  .dis-ab-r {
    order: 2;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: $breakpoint-xl) {
  .dis-ab-l {
    order: 1;
  }

  .dis-ab-r {
    order: 2;
  }
}


.about-us-container {
  margin-top: 50px;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #05afb3;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
  }

  p {
    font-size: 1.0rem;
    line-height: 1.5;
  }

  ul {
    padding-left: 20px;
    list-style-type: disc;

    li {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }

  .card {
    border: none;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0%);
    margin-bottom: 2rem;
  }

  .card-title {
    font-size: 1.5rem;
    color: #05afb3;
    margin-bottom: 1rem;
  }

  .text-center {
    text-align: center;
  }
}

.texy-st {
  text-align: start;
}