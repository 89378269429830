@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Prompt', sans-serif;
}

.text-head-in {
  font-size: 34px;
  color: #05afb3;
  text-align: center;
  letter-spacing: -1px;
}

.text-p-in {
  font-size: 16px;
  color: #000;
  text-align: center;
  font-weight: 500;

}

.pad-tb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

// src/style/Home.scss

.text-head-in {
  text-align: center;
  margin-top: 20px;
}

.text-p-in {
  text-align: center;
  margin-bottom: 40px;
}

.image-overlay {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.image {
  width: 100%;
  height: auto;
}

.text-success {
  color: #05afb3 !important;
}



.btn:hover {
  color: #fff;
  background-color: #05afb3;
  border-color: #05afb3;
}
.btn:active {
  color: #fff;
  background-color: #05afb3 !important;
  border-color: #05afb3 !important;
}

.btn-success {
  background-color: #05afb3;
  color: #fff;
  border-color: #05afb3;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-overlay:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }
}


.home-sec ul li {
  font-size: 25px;
  font-weight: 300;
  line-height: 45px;
  color: #fff;
  text-align: left;
}

.with-marker li::before {
  content: "•";
  color: #fff;
  display: inline-block;
  width: 25px;
  margin-left: 0;
  font-size: 35px;
}

.bg-2color-home {
  background: linear-gradient(323deg, rgba(16, 151, 148, 1) 35%, rgba(38, 157, 122, 1) 100%);
}

// src/style/OnlineServices.scss

.service-box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  transition: transform 0.3s;
}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-box i {
  color: #05afb3;
}

.service-box h5 {
  font-weight: bold;
}

.service-box p {
  color: #6c757d;
  margin: 0;
}