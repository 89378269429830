.register-container {
  margin-top: 50px;

  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #05afb3;
  }

  .p-float-label {
    margin-bottom: 1.5rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      color: #666;
    }

    input,
    textarea,
    .react-datepicker-wrapper input {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #ccc;
      border-radius: 0.25rem;
      font-size: 1rem;
      box-sizing: border-box;
    }

    input:focus,
    textarea:focus,
    .react-datepicker-wrapper input:focus {
      border-color: #007bff;
      outline: none;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }

  .card {
    padding: 2rem;
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  .p-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1.2rem;
    background: #05afb3;
    border: none;
    border-radius: 0.25rem;

    &:hover {
      background-color: #0056b3;
    }
  }

  .line-button {
    background-color: #00c300;
    border: none;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #009900;
    }

    .pi-external-link {
      margin-left: 0.5rem;
    }
  }

  .imgar-banner {
    width: 100%;
    margin-bottom: 2rem;
  }

  // Custom styles for react-datepicker
  .react-datepicker {
    font-size: 1.2rem;
    border: 1px solid #05afb3;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

    .react-datepicker__header {
      background-color: #05afb3;
      color: white;
      border-bottom: none;
      padding: 0px;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: 1.5rem;
      color: white;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2.5rem;
      line-height: 2.5rem;
      margin: 0.2rem;
      color: #000;

      &:hover {
        background-color: #e6f7ff00;
        border-radius: 50%;
        color: #000;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: #368e86;
      color: #fff;
      border-radius: 50%;
    }

    .react-datepicker__day--today {
      font-weight: bold;
      color: #fff;
      background-color: #000;
      border-radius: 50%;
    }

    .react-datepicker__month-dropdown-container,
    .react-datepicker__year-dropdown-container {
      margin: 0.5rem;
    }

    .react-datepicker__month-dropdown,
    .react-datepicker__year-dropdown {
      font-size: 1.2rem;
      background-color: #e6f7ff;
      border: 1px solid #05afb3;
      border-radius: 0.25rem;
      padding: 0.5rem;
    }

    .react-datepicker__navigation {
      top: 1rem;
      line-height: 1.5rem;
      border: none;
      background: transparent;

      &::before {
        content: '';
        border: solid #fff;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
      }
    }

    .react-datepicker__navigation--previous {
      transform: rotate(135deg);
      left: 10px;
    }

    .react-datepicker__navigation--next {
      transform: rotate(-45deg);
      right: 10px;
    }
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    background-color: #e6f7ff;
    border: 1px solid #05afb3;
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 1rem;
  }

  .react-datepicker__month-option,
  .react-datepicker__year-option {
    padding: 0.5rem;

    &:hover {
      background-color: #e6e6e6;
    }
  }
}


.p-float-label label {
  position: relative;
  text-align: initial;
  pointer-events: none;
  top: 50%;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
}

.react-datepicker__navigation-icon {
  display: none;
}

.react-datepicker__day-names {
  background: #cacaca;
}

.react-datepicker-wrapper {
  width: 100%;
}