$breakpoint-xs: 600px;
$breakpoint-sm: 600px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

@media only screen and (max-width: $breakpoint-xs) {
  .dis-nomo {
    display: none;
  }

  .navbar-brand img {
    height: 40px;
  }

  .navbar-toggler {
    background: #000000;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: $breakpoint-sm) {
  .dis-nomo {
    display: none;
  }

  .navbar-brand img {
    height: 40px;
  }
  
  .navbar-toggler {
    background: #000000;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: $breakpoint-md) {
  .dis-nomo {
    display: none;
  }

  .navbar-brand img {
    height: 60px;
  }
  
  .navbar-toggler {
    background: #000000;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: $breakpoint-lg) {
  .dis-nomo {
    display: block;
  }

  .navbar-brand img {
    height: 60px;
  }
  
  .navbar-toggler {
    background: #05afb3;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: $breakpoint-xl) {
  .dis-nomo {
    display: block;
  }

  .navbar-brand img {
    height: 60px;
  }
  
  .navbar-toggler {
    background: #05afb3;
  }
}




// Navbar.scss
.custom-navbar {
  background: linear-gradient(to right, #ccff8d, #ffd39f, #ff90ac);
  font-family: 'Prompt', sans-serif;

  .navbar-brand {
    font-size: 1.55rem; // Larger font size for the brand
  }

  .nav-link {
    font-size: 1.55rem; // Larger font size for nav items
    text-align: center; // Ensure text is centered

    &:hover {
      color: #727272; // Change color on hover
    }

  }
}



.offcanvas {
  width: 100% !important;
  text-align: center;
  background: rgb(0 0 0 / 77%) !important;
  color: #fff;
}

.offcanvas-body {
  display: flex;
  align-items: center;
}

.nav-link {
  font-size: xx-large;
}



.offcanvas-header .btn-close {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e');
  --bs-btn-close-opacity: 1;
  font-size: 35px;
  margin-right: 50px;
  margin-top: 0px;
}


.menu-nav {
  z-index: 2;
  width: 100%;

  @media (max-width: 600px) {
    position: relative;
    background: #000;
  }

  @media (min-width: 600px) {
    position: relative;
    background: #000;
  }

  @media (min-width: 768px) {
    position: relative;
    background: #000;
  }

  @media (min-width: 992px) {
    position: absolute;
    background: #dddfdb00;
  }

  @media (min-width: 1200px) {
    position: absolute;
    background: #dddfdb00;
  }


}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem #fff0 !important;
  background-color: rgb(248 249 250 / 0%) !important;
}

.navbar-toggler {
  border: var(--bs-border-width) solid rgb(0 0 0 / 0%);
}


.navbar {
  padding: 5px;
}

.login-btn {
  background-color: #05afb3;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-right: 0;
  cursor: pointer;
  border-radius: 0;
}

.login-btn:hover {
  background-color: darkgray;
  /* เปลี่ยนสีเมื่อ hover */
}

.ml-buton {
  margin-left: auto;
  padding-right: 10px;
}

.mar-lr-auto {
  margin-left: auto;
  margin-right: auto;
}