.slide-content {
    position: relative;
    width: 100%; // Ensure the container takes full width of the slider
  
    .img-slide {
      width: 100%;
      height: auto; // Maintain aspect ratio
    }
  
    .slide-caption {
      position: absolute;
      top: 50%; // Center vertically
      left: 50%; // Center horizontally
      transform: translate(-50%, -50%); // Adjust to precisely center
      text-align: center;
      color: white; // Ensure text is readable against likely dark backgrounds
      animation: fadeIn 2s ease-out;
  
      h1 {
        margin: 0;
        padding: 0.5rem;
        background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background for readability
      }
  
      p {
        font-size: 1.2rem;
        background-color: rgba(0, 0, 0, 0.5); // Consistent style with the heading
      }
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
