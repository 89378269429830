.article-container {
  .card {
    border: none;

    .card-body {
      .card-title {
        font-size: 1.25rem;
        font-weight: bold;
      }

      .btn-link {
        font-size: 1rem;
        color: black;

        &:hover {
          text-decoration: none;
          color: green;
        }
      }
    }
  }

}


.text-st {
  text-align: start;
}


.pad-tb40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

/*------------------*/

.breadcrumb-nav {
  background-color: #f2f2f2;


  @media (max-width: 600px) {
    padding-left: 0px;
  }

  @media (min-width: 600px) {
    padding-left: 0px;
  }

  @media (min-width: 768px) {
    padding-left: 10px;
  }

  @media (min-width: 992px) {
    padding-left: 10px;
  }

  @media (min-width: 1200px) {
    padding-left: 100px;
  }


  .breadcrumb {
    padding: 10px;
    margin-bottom: 1rem;

    .breadcrumb-item {
      font-size: 1rem;
      color: #000;

      &+.breadcrumb-item::before {
        content: '>';
        color: #6c757d;
      }

      &.active {
        color: #6c757d;
      }

      a {
        text-decoration: none;
        color: #000;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .text-muted {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 0.25rem;
  }
}



.imgar-banner {
  width: 100%;
}


.img-article {
  border-radius: 10px;

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (min-width: 600px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    width: 60%;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }
}

.btn:hover {
  color: #fff;
  background-color: hsla(181, 95%, 36%, 0);
  border-color: hsla(181, 95%, 36%, 0);
}