.article-container {
  .card {
    border: none;
    .card-body {
      .card-title {
        font-size: 1.25rem;
        font-weight: bold;
      }
      .btn-link {
        font-size: 1rem;
        color: black;
        &:hover {
          text-decoration: none;
          color: green;
        }
      }
    }
  }
}


.imgar-banner {
  width: 100%;
}

.text-muted {
  font-size: small;
}