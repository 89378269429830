.footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  border-top: 1px solid #e7e7e7;
  
  p {
    margin: 0;
    color: #333;
    font-size: 0.9rem;
  }

  .social-icon {
    margin: 0 10px;
    color: #555;
    font-size: 1.5rem;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }
  }

  @media (max-width: 767.98px) {
    .text-md-left {
      text-align: center !important;
    }

    .text-md-right {
      text-align: center !important;
    }
  }
}
